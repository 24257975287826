import translateModel from '../../utils/translateModel';

(function () {
  angular
    .module('tw.translate-model', ['pascalprecht.translate'])
    .filter('translateModel', [
      '$translate',
      'mainConfig',
      function ($translate) {
        return function (input, lang = $translate.use()) {
          return translateModel(input, lang);
        };
      },
    ])
    .directive('translateModel', [
      '$parse',
      '$filter',
      function () {
        return {
          restrict: 'A',
          scope: {
            translateModel: '@', // Gets the string contents of the `translate-model` attribute
            translateModelLanguage: '@', // Gets the string contents of the `translate-model-language` attribute
          },
          link: function () {
            // scope.currentLanguage = "en";
            // var model = element.attr("ng-model");
            // console.log("translateModel:"+scope.translateModel);
            // console.log("model:"+model);
            // //console.log("model:"+scope.$parent.$eval(model));
            // scope.$parent.$watch(scope.translateModel,function(value){
            // });
            // scope.$parent.$watch(scope.translateModelLanguage,function(value){
            //   console.log("watch translateModelLanguage:"+value);
            //   if (angular.isUndefined(value)) {return};
            //   scope.currentLanguage = value;
            // });
          },
        };
      },
    ]);
})();
