import LRUCache from '../../utils/lruCache';
import fetcher from '../../utils/fetcher';
import { starIcons } from './starsIcons';

const DEBOUNCED_FETCHED_PRODUCT_REVIEWS_TIME = 100;
const MAX_PRODUCT_REVIEW_RATE = 5;
const productIdSet = new Set();
export const productReviewsCache = new LRUCache(150);

let debounceTimeout = null;
const notifyProductAdded = (productId) => {
  productIdSet.add(productId);

  if (debounceTimeout) clearTimeout(debounceTimeout);

  debounceTimeout = setTimeout(() => {
    fetchAndUpdateProductReviews();
  }, DEBOUNCED_FETCHED_PRODUCT_REVIEWS_TIME);
};

export class ProductReviewStars extends HTMLElement {
  static get observedAttributes() {
    return ['data-product-review'];
  }

  connectedCallback() {
    const productId = this.getAttribute('data-product-id');
    if (productId) {
      notifyProductAdded(productId);
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'data-product-review' && oldValue !== newValue && newValue) {
      this.render();
    }
  }

  showStarRating() {
    const skeletonElement = this.querySelector('.product-review-skeleton');
    if (skeletonElement) {
      skeletonElement.remove();
    }
    const reviewStarsRating = this.querySelector(
      '.product-review-stars-rating.hidden',
    );

    if (reviewStarsRating) {
      reviewStarsRating.classList.remove('hidden');
    }
  }

  render() {
    this.showStarRating();
    if (!this.dataset.productReview) {
      return;
    }
    const review = JSON.parse(this.dataset.productReview);
    const productReviewStarContainer = this.querySelector(
      '.product-review-stars-rating',
    );
    if (productReviewStarContainer && review.avg_score) {
      const rating = review.avg_score;
      for (let i = 0; i <= MAX_PRODUCT_REVIEW_RATE - 1; ++i) {
        const starValue = Number((rating - i).toFixed(1));
        if (starValue >= 1) {
          productReviewStarContainer.innerHTML += `<span class="product-review-star">${starIcons.fullStar}</span>`;
        } else if (starValue > 0 && starValue < 1) {
          productReviewStarContainer.innerHTML += `<span class="product-review-star">${starIcons.halfStar}</span>`;
        } else {
          productReviewStarContainer.innerHTML += `<span class="product-review-star">${starIcons.emptyStar}</span>`;
        }
      }
      const totalCountsElement = document.createElement('span');
      totalCountsElement.className = 'product-review-total-counts';
      totalCountsElement.textContent = `(${review.total_comments_count})`;
      productReviewStarContainer.appendChild(totalCountsElement);
    }
  }
}

if (!customElements.get('product-review-stars')) {
  customElements.define('product-review-stars', ProductReviewStars);
}

export const setProductReviewFromFetchedReviews = (
  productReviewElements,
  newProductReviewsMap,
) => {
  productReviewElements.forEach((element) => {
    const productId = element.getAttribute('data-product-id');

    element.setAttribute(
      'data-product-review',
      JSON.stringify(newProductReviewsMap.get(productId) || {}),
    );
  });
};

export const fetchAndUpdateProductReviews = async () => {
  const productReviewElements = document.querySelectorAll(
    'product-review-stars',
  );

  const productReviewElementsArr = Array.from(productReviewElements);

  const { newProductReviewElements, newProductIds } =
    productReviewElementsArr.reduce(
      (acc, element) => {
        const productId = element.getAttribute('data-product-id');
        if (productReviewsCache.has(productId)) {
          element.setAttribute(
            'data-product-review',
            JSON.stringify(productReviewsCache.get(productId)),
          );
          return acc;
        }

        acc.newProductReviewElements.push(element);
        acc.newProductIds.add(productId);
        return acc;
      },
      {
        newProductReviewElements: [],
        newProductIds: new Set(),
      },
    );

  if (newProductIds.size === 0) {
    return;
  }

  // Deal with product reviews not in cache
  try {
    const queryParams = new URLSearchParams();
    newProductIds.forEach((id) => queryParams.append('product_ids[]', id));
    const fetchedProductReviews = await fetcher(
      `/api/merchants/${
        window.mainConfig.merchantId
      }/products/product_reviews/list?${queryParams.toString()}`,
      {
        method: 'GET',
      },
    ).then((response) => {
      if (!response.ok)
        return Promise.reject({
          status: response.status,
          message: 'Get product reviews failed',
        });
      return response.json();
    });

    const newProductReviewsMap = new Map(
      fetchedProductReviews?.data?.map((review) => [
        review.product_id,
        {
          avg_score: review.avg_score,
          total_comments_count: review.total_comments_count,
        },
      ]),
    );

    setProductReviewFromFetchedReviews(
      newProductReviewElements,
      newProductReviewsMap,
    );

    // Update cache
    newProductIds.forEach((productId) => {
      const isProductInProductReviewMap = newProductReviewsMap.has(productId);
      productReviewsCache.set(
        productId,
        isProductInProductReviewMap ? newProductReviewsMap.get(productId) : {},
      );
    });
  } catch (error) {
    const productReviewElements = document.querySelectorAll(
      'product-review-stars',
    );
    productReviewElements.forEach((el) => {
      el.showStarRating();
    });
    console.error('Failed to fetch product reviews:', error);
  }
};

window.addEventListener('beforeunload', () => {
  productIdSet.clear();
  if (debounceTimeout) clearTimeout(debounceTimeout);
});
