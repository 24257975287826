angular
  .module('sl.hide-field-by-country', [])
  .directive('hideFieldByCountry', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        //fields to be hidden according to field names
        var hiddenFields = {
          TW: [
            //delivery address fields
            'delivery_address_address_2',
            'delivery_address_state',

            //billing address fields
            'billing_address_address_2',
            'billing_address_state',

            //memebership addresss fields
            'addr_address_2',
            'addr_state',
          ],
          HK: [
            //delivery address fields
            'delivery_address_city',
            'delivery_address_state',
            'delivery_address_postcode',

            //billing address fields
            'billing_address_city',
            'billing_address_state',
            'billing_address_postcode',

            //memebership addresss fields
            'addr_city',
            'addr_state',
            'addr_postcode',
          ],
        };

        var fieldName = element.find('input').attr('name');
        var modelName = element.find('input').attr('ng-model');

        scope.$watch(attrs.hideFieldByCountry, function () {
          // attrs.addressField is a string of a variable name
          // when evaluated, it becomes a country code

          if (scope.$eval(attrs.hideFieldByCountry)) {
            var country = scope.$eval(attrs.hideFieldByCountry);
            //get the isolated scope of the control group so we can store variables there
            var innerscope = angular.element(element.find('input')).scope();

            if (
              hiddenFields[country] &&
              _.find(hiddenFields[country], function (field) {
                return fieldName.indexOf(field) !== -1;
              })
            ) {
              element.css('display', 'none');
              innerscope.fieldHiddenByCountry = true;
              //comments this to un-sanitize hidden field
              scope.$eval(modelName + "=''");
            } else {
              innerscope.fieldHiddenByCountry = false;
              element.css('display', 'inherit');
            }
          }
        });
      },
    };
  });
