(function () {
  angular.module('tw.useragent', []).service('userAgentService', function () {
    this.isMobi = function () {
      return /iPhone|iPod|iPad|Android|BlackBerry|BB10|RIM Tablet|Windows Phone|SymbianOS|Kindle|Silk/.test(
        navigator.userAgent,
      );
    };
    /**
     * This might not work for chrome on mobile.
     */
    this.isAndroid = function () {
      var ua = navigator.userAgent || navigator.vendor || window.opera;
      return /Android/.test(ua);
    };
    this.isBlackBerry = function () {
      return /BlackBerry|BB10|RIM Tablet/.test(navigator.userAgent);
    };
    this.isiOS = function () {
      return (
        /iPhone|iPod|iPad/.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      );
    };
    this.isWindowsPhone = function () {
      return /Windows Phone/.test(navigator.userAgent);
    };

    this.isChrome = function () {
      return !!window.chrome;
    };

    this.isDesktopChrome = function () {
      var isMobile = navigator.userAgent.match(/Mobi/i);

      if (isMobile) {
        return true;
      } else {
        return !!window.chrome;
      }
    };

    this.isDesktopIE = function () {
      var isMobile = navigator.userAgent.match(/Mobi/i);

      if (isMobile) {
        return false;
      } else {
        return /MSIE/.test(navigator.userAgent);
      }
    };

    this.isFirefox = function () {
      return navigator.userAgent.search('Firefox') >= 0;
    };

    this.isInstagramBrowser = function () {
      var ua = navigator.userAgent || navigator.vendor || window.opera;
      return ua.includes('Instagram');
    };

    this.isFacebookBrowser = function () {
      var ua = navigator.userAgent || navigator.vendor || window.opera;
      return ua.includes('FBAN') || ua.includes('FBAV');
    };

    this.getAndroidVersion = function () {
      var ua = navigator.userAgent || navigator.vendor || window.opera;
      var validated = /Android (\d+(?:\.\d+)*)/.exec(ua);
      if (validated) {
        return validated[1];
      }
    };
  });
})();
