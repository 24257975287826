export const starIcons = {
  fullStar: `
      <svg 
        width="14" 
        height="14" 
        viewBox="0 0 14 14" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          fill-rule="evenodd" 
          clip-rule="evenodd" 
          d="M12.5678 6.16841C12.5262 6.3598 12.4347 6.53038 12.2932 6.68016L10.4085 8.60234L10.8578 11.3234C10.9077 11.6562 10.8245 11.9495 10.6082 12.2033C10.3918 12.4571 10.1172 12.584 9.78438 12.584C9.60132 12.584 9.42657 12.5382 9.26015 12.4467L7.00096 11.1985L4.74177 12.4467C4.57534 12.5382 4.4006 12.584 4.21754 12.584C3.88469 12.584 3.61009 12.4571 3.39374 12.2033C3.17739 11.9495 3.09418 11.6562 3.14411 11.3234L3.59345 8.60234L1.70871 6.68016C1.56725 6.53038 1.47364 6.35772 1.42787 6.16217C1.3821 5.96662 1.39251 5.77107 1.45908 5.57553C1.52564 5.37998 1.6359 5.21772 1.78984 5.08874C1.94378 4.95976 2.12061 4.87863 2.32031 4.84535L4.87907 4.45841L6.02739 2.02448C6.11892 1.82477 6.25622 1.67083 6.43928 1.56265C6.61403 1.45448 6.80125 1.40039 7.00096 1.40039C7.20067 1.40039 7.38789 1.45448 7.56264 1.56265C7.7457 1.67083 7.883 1.82477 7.97453 2.02448L9.12285 4.45841L11.6816 4.84535C11.8813 4.87863 12.0581 4.95976 12.2121 5.08874C12.366 5.21772 12.4763 5.37998 12.5428 5.57553C12.6094 5.77107 12.6177 5.9687 12.5678 6.16841Z" 
          fill="#FFC500"
        />
      </svg>
    `,
  halfStar: `
      <svg 
        width="14" 
        height="14" 
        viewBox="0 0 14 14" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          fill-rule="evenodd" 
          clip-rule="evenodd" 
          d="M6.9999 1.40039C6.80059 1.40058 6.61372 1.45467 6.43928 1.56265C6.25622 1.67083 6.11892 1.82477 6.02738 2.02448L4.87907 4.45841L2.32031 4.84534C2.12061 4.87863 1.94378 4.95976 1.78984 5.08874C1.6359 5.21772 1.52564 5.37998 1.45908 5.57553C1.39251 5.77107 1.3821 5.96662 1.42787 6.16217C1.47364 6.35771 1.56725 6.53038 1.70871 6.68016L3.59345 8.60234L3.14411 11.3234C3.09418 11.6562 3.17739 11.9495 3.39374 12.2033C3.61009 12.4571 3.88469 12.584 4.21754 12.584C4.4006 12.584 4.57534 12.5382 4.74177 12.4467L6.9999 11.1991V1.40039Z" 
          fill="#FFC500"
        />
        <path 
          fill-rule="evenodd" 
          clip-rule="evenodd" 
          d="M7.0001 1.40039C7.19941 1.40058 7.38628 1.45467 7.56072 1.56265C7.74378 1.67083 7.88108 1.82477 7.97262 2.02448L9.12093 4.45841L11.6797 4.84535C11.8794 4.87863 12.0562 4.95976 12.2102 5.08874C12.3641 5.21772 12.4744 5.37998 12.5409 5.57553C12.6075 5.77107 12.6179 5.96662 12.5721 6.16217C12.5264 6.35771 12.4328 6.53038 12.2913 6.68016L10.4066 8.60234L10.8559 11.3234C10.9058 11.6562 10.8226 11.9495 10.6063 12.2033C10.3899 12.4571 10.1153 12.584 9.78247 12.584C9.5994 12.584 9.42466 12.5382 9.25823 12.4467L7.0001 11.1991V1.40039Z" 
          fill="#F6F6F6"
        />
      </svg>
    `,
  emptyStar: `
      <svg 
        width="14" 
        height="14" 
        viewBox="0 0 14 14" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          fill-rule="evenodd" 
          clip-rule="evenodd" 
          d="M12.5678 6.16841C12.5262 6.3598 12.4347 6.53038 12.2932 6.68016L10.4085 8.60234L10.8578 11.3234C10.9077 11.6562 10.8245 11.9495 10.6082 12.2033C10.3918 12.4571 10.1172 12.584 9.78438 12.584C9.60132 12.584 9.42657 12.5382 9.26015 12.4467L7.00096 11.1985L4.74177 12.4467C4.57534 12.5382 4.4006 12.584 4.21754 12.584C3.88469 12.584 3.61009 12.4571 3.39374 12.2033C3.17739 11.9495 3.09418 11.6562 3.14411 11.3234L3.59345 8.60234L1.70871 6.68016C1.56725 6.53038 1.47364 6.35772 1.42787 6.16217C1.3821 5.96662 1.39251 5.77107 1.45908 5.57553C1.52564 5.37998 1.6359 5.21772 1.78984 5.08874C1.94378 4.95976 2.12061 4.87863 2.32031 4.84535L4.87907 4.45841L6.02739 2.02448C6.11892 1.82477 6.25622 1.67083 6.43928 1.56265C6.61403 1.45448 6.80125 1.40039 7.00096 1.40039C7.20067 1.40039 7.38789 1.45448 7.56264 1.56265C7.7457 1.67083 7.883 1.82477 7.97453 2.02448L9.12285 4.45841L11.6816 4.84535C11.8813 4.87863 12.0581 4.95976 12.2121 5.08874C12.366 5.21772 12.4763 5.37998 12.5428 5.57553C12.6094 5.77107 12.6177 5.9687 12.5678 6.16841Z" 
          fill="#F6F6F6"
        />
      </svg>
    `,
};
