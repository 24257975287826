(function () {
  'use strict';

  angular.module('tw.ladda', []).directive('ladda', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        //var l = Ladda.create(element);
        scope.$watch(attrs.ladda, function (newVal) {
          if (newVal !== undefined) {
            if (newVal) {
              var l = Ladda.create(element[0]);
              //var l = Ladda.create(document.querySelector('#'+attrs.id));
              l.start();
            } else {
              Ladda.stopAll();
              //var l = Ladda.create(document.querySelector('#'+attrs.id));
              //l.stop();
            }
          }
        });
      },
    };
  });
})();
