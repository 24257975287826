export default function (FEATURES_LIMIT) {
  return {
    // Get feature limit value of given feature key
    getFeatureLimit: function (feature_key, defaultValue) {
      if (defaultValue === undefined) {
        defaultValue = 0;
      }
      return FEATURES_LIMIT[feature_key] || defaultValue;
    },

    // For updating local display
    updateFeatureLimit: function (feature_key, value) {
      return (FEATURES_LIMIT[feature_key] = value);
    },

    // For extremely simple use case that only check existence of feature
    hasFeature: function (feature_key) {
      return (
        FEATURES_LIMIT[feature_key] != undefined &&
        FEATURES_LIMIT[feature_key] != false
      );
    },
  };
}
