angular.module('sl.change-currency-filter', []).filter('changeCurrency', [
  'mainConfig',
  function (mainConfig) {
    return function (price) {
      if (!price) return;
      if (!mainConfig.isMultiCurrencyEnabled) return price;
      var oldCurrency = mainConfig.merchantData.base_currency_code;
      var newCurrency =
        Currency.cookie.read() ||
        mainConfig.currencyData.requestCountryCurrencyCode;

      return Currency.convertPrice(price, oldCurrency, newCurrency);
    };
  },
]);
