import './service.intl-tel-input';
import { isUndefined } from 'lodash-es';

/**
 * @typedef PhoneValue
 * @prop {string} phone
 * @prop {string} dialCode - country calling code
 */

/**
 * @typedef IntlTelClassConfig
 * @prop {string | undefined} defaultCountry - ISO 3316-2 2-digit, lower case
 * @prop {PhoneValue} phoneValue
 */

app.factory('IntlTelClass', [
  'intlTelInputService',
  'mainConfig',
  function (intlTelInputService, mainConfig) {
    return class IntlTelClass {
      /**
       * @param { jQuery objects } element
       * @param {IntlTelClassConfig} config
       */
      constructor(element, config) {
        this.element = element;
        this.config = config;
        const {
          defaultCountry = mainConfig.requestCountry.toLowerCase(),
          phoneValue = {},
          ...rest
        } = this.config ?? {};
        const finalCountry = Number.isInteger(Number(defaultCountry))
          ? intlTelInputService.toAbbr(defaultCountry)
          : defaultCountry;
        intlTelInputService.initSetting(this.element, {
          userCountry: finalCountry,
          ...rest,
        });
        intlTelInputService.initValue(this.element, {
          defaultCountry: finalCountry,
          ...phoneValue,
        });
      }

      static getIntlTelConfig(phone, countryCode) {
        let dialCode = countryCode || undefined;
        // null will leave country calling code blank
        if (phone && !dialCode) {
          dialCode = null;
        }
        return {
          defaultCountry: isUndefined(dialCode)
            ? mainConfig.requestCountry.toLowerCase()
            : dialCode,
          phoneValue: {
            dialCode,
            phone,
          },
        };
      }

      getCountry(type) {
        return intlTelInputService.getCountry(type, this.element);
      }

      setCountry(type, value) {
        const isSuccess = intlTelInputService.setCountry(
          type,
          this.element,
          value,
        );
        if (isSuccess) {
          this.element.trigger('countrychange', value);
        }
        return isSuccess;
      }

      getPhone(type) {
        return intlTelInputService.getPhone(type, this.element);
      }

      setPhone(type, value) {
        this.element.trigger('formattedphonechange', value);
        return intlTelInputService.setPhone(type, this.element, value);
      }

      setOnCountryChange(callback) {
        return this.element.on('countrychange', callback);
      }

      setOnFomattedPhoneChange(callback) {
        return this.element.on('formattedphonechange', callback);
      }

      reset(newConfig) {
        return intlTelInputService.reset(
          this.element,
          newConfig ?? this.config,
        );
      }
    };
  },
]);
