class LRUCache extends Map {
  constructor(maxSize) {
    super();
    this.maxSize = maxSize;
  }

  get(key) {
    if (!super.has(key)) return null;
    const value = super.get(key);
    super.delete(key);
    super.set(key, value);
    return value;
  }

  set(key, value) {
    if (this.size >= this.maxSize) {
      // Evict the least recently used (oldest) item
      const oldestKey = this.keys().next().value;
      super.delete(oldestKey);
    }
    super.set(key, value);
    return this;
  }
}

export default LRUCache;
