// shop.js entry
import '../shop/tw-modules/intlTelInput';
import '../shop/tw-modules/tw.translate-model';
import '../shop/tw-modules/tw.useragent';
import '../shop/tw-modules/tw.lightbox';
import '../shop/tw-modules/tw.filepicker';
import '../shop/tw-modules/tw.dynamic-name';
import '../shop/tw-modules/tw.control-group';
import '../shop/tw-modules/sl.hide-field-by-country';
import '../shop/tw-modules/sl.change-currency-filter';
import '../shop/tw-modules/sl.promotion-title-filter';
import '../shop/tw-modules/tw.ladda';
import '../shop/createApp.js';
import slFeatureService from '../lib/sl-feature';
import '../customElements/product_review_stars';

window.app.service('slFeatureService', ['FEATURES_LIMIT', slFeatureService]);
