/**
 * @deprecated
 * This file is deprecated. Please use tracker.js instead.
 * The files that import this file are too many, keep the path for now.
 * TODO: replace all the import of this file to tracker.js
 */

export {
  EVENT_NAME_FOR_USER_FLOW,
  EVENT_CATEGORY_FOR_USER_FLOW,
  VALUE_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
} from './tracker';
