// this code from shop repo app/javascript/packs/shop/tw-modules/tw.translate-model.js
export default function getTranslateModel(
  input,
  lang = document.querySelector('html').getAttribute('lang'),
) {
  if (!input || _.isEmpty(input)) {
    return '';
  }
  if (typeof input === 'string') {
    return input;
  }

  var supported_languages = window.mainConfig.merchantData.supported_languages;

  // Required language present in given input hash
  if (_.contains(supported_languages, lang) && !_.isEmpty(input[lang])) {
    return input[lang];
  }

  // Language code that need special handling
  var available_fallbacks = [];
  switch (lang) {
    case 'zh-cn':
      available_fallbacks = _.intersection(_.keys(input), supported_languages, [
        'zh-cn',
        'zh-hant',
        'zh-hk',
        'zh-tw',
        'en',
      ]);
      break;
    case 'zh-hant':
    case 'zh-hk':
    case 'zh-tw':
      available_fallbacks = _.intersection(_.keys(input), supported_languages, [
        'zh-tw',
        'zh-hant',
        'zh-hk',
        'zh-cn',
        'en',
      ]);
      break;
    default:
      available_fallbacks = _.intersection(_.keys(input), supported_languages, [
        'en',
      ]);
  }

  function valueFromFallbackLanguages() {
    return _.chain(input)
      .pick(function (v, k) {
        return _.contains(available_fallbacks, k);
      })
      .values()
      .reject(function (val) {
        return _.isEmpty(val);
      })
      .first()
      .value();
  }

  function valueFromExistingKeys() {
    return _.chain(input)
      .values()
      .reject(function (val) {
        return _.isEmpty(val);
      })
      .first()
      .value();
  }

  return valueFromFallbackLanguages() || valueFromExistingKeys() || '';
}
