const fetcher = (endpoint, options) => {
  const csrfToken = document
    .querySelector('meta[name=csrf-token]')
    ?.getAttribute('content');
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken,
    'X-Requested-With': 'XMLHttpRequest',
  };
  const url = new URL(endpoint, window.location.origin);

  return fetch(url.toString(), {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options?.headers,
    },
  });
};

export default fetcher;
